import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout
    isHome={true}
    fgColor="#36A8F5"
    hasFooter
  >
    <SEO title="Make–Ready | 404" />
    <div className="innerx8 container">
      <p>Not found - 404</p>
    </div>
  </Layout>
)

export default NotFoundPage
